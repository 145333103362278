import React from "react";
import {useHistory} from "react-router";
import {useDispatch, useSelector} from "react-redux";
import {verifyAccount, resendVerificationToken} from "../../redux/actions/sessionActions";

export const EmailVerification = (props) => {

    const history = useHistory();
    const dispatch = useDispatch();
    const query = new URLSearchParams(props.location.search);
    const token = query.get("token");

    const {loaders, errors, verifyAccountStatus} = useSelector(state => state.sessionHandler)

    const onVerify = () => {
        dispatch(verifyAccount(token, history));
    };

    const onResendToken = async () => {
        dispatch(resendVerificationToken(token, history))
    };

    const getStatusText = () => {
        switch (verifyAccountStatus) {
            case 1000:
                return {
                    alert: 'Account Verified',
                    button: 'Redirecting...'
                }
            case 1001:
                return {
                    alert: 'Account is already Verified',
                    button: 'Redirecting...'
                }
            case 1002:
                return {
                    alert: 'Your token is expired',
                    button: 'Resend your token'
                }
            case 1003:
                return {
                    alert: 'Invalid token',
                    button: 'Verify Email'
                }
            case 1004:
                return {
                    alert: errors.verify,
                    button: 'Verify Email'
                }
            default:
                return {
                    alert: errors.verify,
                    button: 'Verify Email'
                }
        }
    }

    return (
        <React.Fragment>
            <section>
                <div className="container">
                    <div className="col-md-12 text-center">
                        {verifyAccountStatus !== 0 && <div
                            className={[
                                "alert",
                                verifyAccountStatus === 1000 || verifyAccountStatus === 1004 ? 'alert-success' : 'alert-danger'
                            ].join(' ')}
                            style={{padding: 10}}
                        >
                            {getStatusText().alert}
                        </div>
                        }
                    </div>
                    <div className="consult-logo">
                        <img
                            id="c-logo"
                            src={"images/logo-black.webp"}
                            alt="fx-logo"
                        />
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div
                                className="title d-inline-block"
                                style={{marginTop: "40px"}}
                            >
                                <h2>Verify your Email Address</h2>
                            </div>
                        </div>
                        <div className="col-md-12 text-center">
                            <div className="d-inline-block">
                                <h5>
                                    Please click verify button below to verify
                                    your account.
                                </h5>
                            </div>

                            <div
                                className="col-md-12 text-center"
                                style={{marginTop: "20px"}}
                            >
                                <button
                                    className="btn btn-small btn-gradient btn-rounded mt-4"
                                    disabled={loaders.verify ? 'disabled' : ''}
                                    onClick={() => verifyAccountStatus === 1002 ? onResendToken() : onVerify()}
                                >
                                    {loaders.verify && <i className="fa fa-spinner fa-spin mr-2" aria-hidden="true"/>}
                                    {getStatusText().button}
                                </button>
                            </div>
                            <section className="bg-white text-center">
                                <h2 className="d-none">hidden</h2>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="footer-social">
                                                <ul className="list-unstyled">
                                                    <li>
                                                        <a
                                                            className="wow fadeInDown"
                                                            href="https://www.linkedin.com/company/fxisai/mycompany"
                                                            target="_blank"
                                                        >
                                                            <i
                                                                className="fa fa-linkedin"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="wow fadeInUp"
                                                            href="https://www.facebook.com/fx.data.labs/"
                                                            target="_blank"
                                                        >
                                                            <i
                                                                className="fa fa-facebook"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="wow fadeInUp"
                                                            href="https://www.instagram.com/fxis.ai/"
                                                            target="_blank"
                                                        >
                                                            <i
                                                                className="fa fa-instagram"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="wow fadeInDown"
                                                            href="https://twitter.com/fxis_ai"
                                                            target="_blank"
                                                        >
                                                            <i
                                                                className="fa fa-twitter"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="wow fadeInDown"
                                                            href={
                                                                "mailto:contact@fxis.ai"
                                                            }
                                                        >
                                                            <i
                                                                className="fa fa-envelope-o"
                                                                aria-hidden="true"
                                                            />
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <p className="company-about fadeIn">
                                                &copy; 2020 Fx is Ai Pvt.
                                                Ltd. All rights reserved.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
};
