import React, { useEffect, useState } from "react";
import Home from "../../Components/Home/HomeSection/Home";
import { Helmet } from "react-helmet";
import OnDemandLoader from "../../Components/Home/OnDemandLoader";
import scriptLoader from "../../utils/javascriptsLoader";
import "react-loading-skeleton/dist/skeleton.css";

const TOTAL_COMPONENTS = 8;
const SCROLL_DELAY = 1500;
const INITIAL_DELAY = 2000;

const UserHome = (props) => {
  const [loadingState, setLoadingState] = useState({
    loadedComponentsCount: 0,
    isVideoLoaded: false,
    shouldStartLoading: false
  });

  // Handle initial loading delay
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoadingState(prev => ({
        ...prev,
        shouldStartLoading: true
      }));
    }, INITIAL_DELAY);

    return () => clearTimeout(timer);
  }, []);

  // Handle script loading when components are loaded
  useEffect(() => {
    if (loadingState.loadedComponentsCount >= TOTAL_COMPONENTS) {
      loadAppCarousel();
      handleHashScroll();
    }
  }, [loadingState.loadedComponentsCount, props.location.hash]);

  const handleHashScroll = () => {
    if (props.location.hash) {
      setTimeout(() => {
        const element = document.getElementById(props.location.hash.replace("#", ""));
        element?.scrollIntoView({ behavior: "smooth", block: "start" });
      }, SCROLL_DELAY);
    }
  };

  const handleVideoLoaded = () => {
    setLoadingState(prev => ({
      ...prev,
      isVideoLoaded: true
    }));
  };

  const addToList = () => {
    setLoadingState(prev => ({
      ...prev,
      loadedComponentsCount: Math.min(prev.loadedComponentsCount + 1, TOTAL_COMPONENTS)
    }));
  };

  const loadAppCarousel = () => {
    const scripts = [
      {
        src: `${process.env.REACT_APP_BASE_URL}js/modules/technologies.js?ca93809a-cd54-40d0-ad2c-a763aba41857`,
        id: "technologiesId"
      },
      {
        src: `${process.env.REACT_APP_BASE_URL}js/modules/services.js?ca93809a-cd54-40d0-ad2c-a763aba41856`,
        id: "servicesId"
      }
    ];

    scripts.forEach(script => {
      scriptLoader({
        ...script,
        async: 1,
        defer: 1
      });
    });
  };

  return (
    <React.Fragment>
      {/* <Helmet>
        <title>fxis.ai | Artificial Intelligence & Data Science Experts</title>
        <meta
          name="description"
          content="fxis.ai specializes in cutting-edge Data Science, Machine Learning, and Artificial Intelligence solutions, including Generative AI and advanced custom software development. Our expertise covers a comprehensive range of major technology stacks within data science, delivering next-generation solutions tailored to meet evolving business needs. "
        />
      </Helmet> */}
      
      <Home
        isVideoLoaded={loadingState.isVideoLoaded}
        shouldStartLoading={loadingState.shouldStartLoading}
        handleVideoLoaded={handleVideoLoaded}
      />
      
      <OnDemandLoader 
        {...props} 
        addToList={addToList} 
      />
    </React.Fragment>
  );
};

export default UserHome;