import React, { Suspense } from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "./Layout/Layout";
import {EmailVerification} from "./Components/Auth/EmailVerification";

const App = () => {

    return (
        <Router>
            <Helmet>
        <title>fxis.ai | Artificial Intelligence & Data Science Experts</title>
        <meta name="description" content={"fxis.ai specializes in cutting-edge Data Science, Machine Learning, and Artificial Intelligence solutions, including Generative AI and advanced custom software development. Our expertise covers a comprehensive range of major technology stacks within data science, delivering next-generation solutions tailored to meet evolving business needs."} />
      </Helmet>          
            <Switch>
                <Route
                    path="/verify"
                    exact
                    render={(props) => (
                        <EmailVerification
                            {...props}
                        />
                    )}
                />

                <Route path={"/"} component={Layout}/>

            </Switch>
        </Router>
    );
}

export default App;
