import React, { useState , useEffect } from "react";
import {Helmet} from "react-helmet";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HireAIExpertModal from "../../Components/Modals/HireAIExpertModal/HireAIExpertModal";
import SucceesModal from "../../Components/whatsappBot/SucceesModal";

export default function Hirenow() {
    const history = useHistory();
    const { t } = useTranslation();
  
  // State for managing modal visibility and other form-related states
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [formStatus, setFormStatus] = useState({ isSuccess: false, isLoading: false, error: "" });
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const closeModal = () => {
    setIsModalVisible(false);
    setErrorMessage(null);
    setPhoneNumber("");
    setEmail("");
    setFormStatus({ isLoading: false, isSuccess: false, error: null });
  };

  const handleSuccess = () => {
    setIsSuccessModalVisible(true);
  };

  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
  };

  const submitWhatsappMessage = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(`${process.env.REACT_APP_WHATSAPP_API_CALL}send`, {
        ...data,
      });
      setFormStatus({ isLoading: false, isSuccess: true });
      closeModal();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };

  const submitEmailForm = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(
        `${process.env.REACT_APP_APIURL}contact`,
        { ...data, },
        { headers: { "Content-Type": "application/json" } }
      );
      setFormStatus({ isLoading: false, isSuccess: true });
      closeModal();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };

  useEffect(() => {
    if (formStatus.error) {
      setTimeout(() => setFormStatus({ ...formStatus, error: null }), 3000);
    }
  }, [formStatus]);

  

    return (
        <React.Fragment>
            <Helmet>
                <title>Join Our Team</title>
                <meta name="description" content="Become part of a pioneering team that's changing the world with AI. Discover career opportunities that shape the future." />
            </Helmet>
            <div style={{paddingTop:"140px"}} className="container-carrer">
                <div className="col-md-6 mt-0">
                    <h4 className="text-l1">{t("careers.fordevelopers")}</h4>
                    <h1 className="text-l2">
                    {t("careers.desc1")}
                    </h1>
                    <p className="text-l3">
                    {t("careers.desc2")}
                    </p>

                    <button
                        className="btn btn-apply"
                        onClick={() => history.push("/apply_now")}
                    >
                       {t("careers.applynow")}
                    </button>

                    <p className="text-look">
                    {t("hirenow.lookingforjob")}{" "}
                      <span className="link-job"
              style={{ cursor: "pointer", color: "#5928d1" }}
              onClick={() => setIsModalVisible(true)} // Open modal on click
            >
                        {t("careers.hiredevelopers")}
                     </span>
                    </p>
                </div>
                <div className="col-md-6 mt-0 main-img2">
                    <img
                        src="/images/frame-carrer.svg"
                        className="carrer-img"
                        alt="img"
                    />
                </div>
            </div>

      {/* HireAIExpertModal Integration */}
      {isModalVisible && (
        <HireAIExpertModal
          isModalVisible={isModalVisible}
          resetForm={closeModal}
          formStatus={formStatus}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleEmailSubmission={submitEmailForm}
          handleWhatsappSubmission={submitWhatsappMessage}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )}

      {isSuccessModalVisible && (
        <SucceesModal
          SuccessModalVisible={isSuccessModalVisible}
          onclose={handleCloseSuccessModal} // Use correct handler for closing
        />
      )}
        </React.Fragment>
    );
}
