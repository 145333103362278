import React, { Suspense, lazy } from 'react';

// Lazy loaded components
const OurCulture = lazy(() => import('./OurCultureSection/OurCulture'));
const Clientele = lazy(() => import('./AboutSection/Clientele'));
const HireExExperts = lazy(() => import('./HireExExpertSection/HireExExperts'));
const Testimonial = lazy(() => import('./TestimonySection/Testimonial'));
const ServiceSection = lazy(() => import('./ServiceSection/ServiceSection'));
const Awards = lazy(() => import('./AwardSection/Awards'));
const GwrSection = lazy(() => import('./GwrSection/GwrSection'));
const Products = lazy(() => import('./ProductSection/Products'));
const Info = lazy(() => import('./Info'));
const Footer = lazy(() => import('./FooterSection/Footer'));
const OurFounders = lazy(() => import('./OurFounderSection/OurFounders'));
const LatestArticle = lazy(() => import('./LatestArticleSection/LatestArticle'));

// Skeleton loader component
const SkeletonLoader = React.memo(() => (
  <div className="skeleton-loader">
    <div className="skeleton-line"></div>
    <div className="skeleton-line"></div>
    <div className="skeleton-line"></div>
  </div>
));

// Memoized components
const MemoizedOurCulture = React.memo(OurCulture);
const MemoizedClientele = React.memo(Clientele);
const MemoizedHireExExperts = React.memo(HireExExperts);
const MemoizedTestimonial = React.memo(Testimonial);
const MemoizedServiceSection = React.memo(ServiceSection);
const MemoizedAwards = React.memo(Awards);
const MemoizedGwrSection = React.memo(GwrSection);
const MemoizedProducts = React.memo(Products);
const MemoizedInfo = React.memo(Info);
const MemoizedFooter = React.memo(Footer);
const MemoizedOurFounders = React.memo(OurFounders);
const MemoizedLatestArticle = React.memo(LatestArticle);

const OnDemandLoader = () => {

  const components = [
    
    { name: 'Clientele', Component: MemoizedClientele },
    { name: 'HireExExperts', Component: MemoizedHireExExperts },
    { name: 'Testimonial', Component: MemoizedTestimonial },
    { name: 'ServiceSection', Component: MemoizedServiceSection },
    { name: 'Awards', Component: MemoizedAwards },
    { name: 'GwrSection', Component: MemoizedGwrSection },
    { name: 'Products', Component: MemoizedProducts },
    { name: 'OurCulture', Component: MemoizedOurCulture },
    { name: 'OurFounders', Component: MemoizedOurFounders },
    { name: 'LatestArticle', Component: MemoizedLatestArticle },
    { name: 'Info', Component: MemoizedInfo },
    { name: 'Footer', Component: MemoizedFooter },
  ];

  return (
    <>
      {components.map(({ name, Component }) => (
        <Suspense key={name} fallback={<SkeletonLoader />}>
          <Component />
        </Suspense>
      ))}
    </>
  );
};

export default OnDemandLoader;